import Providers from 'src/components/Providers'
import StandardScreen from 'src/scopes/nav/StandardScreen'
import Dev from 'src/scopes/dev/Dev'
import { ModalController } from 'src/scopes/modal/Modal'
import RouteController from 'src/scopes/nav/RouteController'
import { isServer } from 'src/utils/isServer'
import { StandardScreenProps } from './constants'
import { ClientGate } from 'src/components/ClientGate'

export default function createScreenLayout(screenProps: StandardScreenProps | null) {
  return function getLayout(Component: JSX.Element) {
    if (isServer && !screenProps?.publicScreen) return null
    return (
      <Providers>
        <ModalController>
          {screenProps ? <StandardScreen {...screenProps}>{Component}</StandardScreen> : Component}
          <ClientGate>
            <RouteController />
            <Dev />
          </ClientGate>
        </ModalController>
      </Providers>
    )
  }
}
