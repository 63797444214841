import React, { ReactNode } from 'react'
import { useAuth } from './scopes/auth/AuthContext'
import { Authenticated } from './scopes/auth/constants'

import { ChatProvider } from './scopes/chat/ChatContext'
import { ChannelsProvider } from './scopes/chat/ChannelContext'
import { LikeProvider } from './scopes/likes/LikeContext'

type Props = {
  children: ReactNode
}

export default function Providers({ children }: Props) {
  let { authenticated } = useAuth()
  if (authenticated !== Authenticated.Yes) return <>{children}</>
  return (
    <ChatProvider>
      <ChannelsProvider>
        <LikeProvider>{children}</LikeProvider>
      </ChannelsProvider>
    </ChatProvider>
  )
}
