import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useAuth, useUser } from '../auth/AuthContext'
import { setSuperProperties, updateAnalyticsUser } from './mixpanel'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { UserProfile } from '@commonstock/common/src/api/profile'
import { Authenticated } from '../auth/constants'
import { ldAnonUser } from './constants'
import { useTheme } from 'src/theme/ThemeContext'

function getLdUser(user: UserProfile) {
  return {
    key: user.user_uuid,
    avatar: user.picture,
    email: user.email,
    name: user.name,
    custom: {
      verified: !!user.verified,
      username: user.username || ''
    }
  }
}
export default function AnalyticsController() {
  let { authenticated } = useAuth()
  let [user] = useUser()
  const ldClient = useLDClient()
  let { theme } = useTheme()
  useEffect(() => {
    setSuperProperties({ theme })
  }, [theme])

  useEffect(() => {
    if (user) {
      updateAnalyticsUser(user)
      Sentry.setUser({
        id: user.user_uuid,
        username: user.username,
        email: user.email
      })
      ldClient?.identify(getLdUser(user))
    }
    if (authenticated === Authenticated.No) {
      ldClient?.identify(ldAnonUser)
    }
  }, [ldClient, user, authenticated])
  return null
}
